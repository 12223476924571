import _ from "lodash";
import moment from "moment";

/* Replace '\' icon to '*' */
export const handleChangeFilterInOperator = (data: any) => {
  const newStr = data.toString().replace(/,/g, "*");
  return newStr;
};

/* Enter the filter information to be sent for the 'where query' in the API call. */
export const handleFilterDataCustomer = (
  locationFilterList: any,
  createdStartDate: any,
  createdEndDate: any,
  lastOrderStartDate: any,
  lastOrderEndDate: any,
) => {
  let location: any = "";
  let createdDate: any = "";
  let lastOrderDate: any = "";
  let allFilteredList: any = "";

  // If the locationFilterList is not empty, add the location details.
  if (!_.isEmpty(locationFilterList)) {
    // If the page is 'order', add the locationFilterList details to location.
    // If the page is not 'order', add the locationFilterList details to location.
    const newStr = locationFilterList.toString().replace(/,/g, ",");
    location = "locationId=in=(" + newStr + ")";
  }

  if (!_.isEmpty(createdEndDate)) {
    createdDate =
      "created>=" +
      moment(createdStartDate, "YYYY-MM-DD").format("YYYY-MM-DDTHH:mm:ss") + ";" +
      "created<=" +
      moment(createdEndDate, "YYYY-MM-DD").endOf('day').format("YYYY-MM-DDTHH:mm:ss");
  }

  if (!_.isEmpty(lastOrderEndDate)) {
    lastOrderDate =
      "lastOrder>=" +
      moment(lastOrderStartDate, "YYYY-MM-DD").format("YYYY-MM-DDTHH:mm:ss") + ";" +
      "lastOrder<=" +
      moment(lastOrderEndDate, "YYYY-MM-DD").endOf('day').format("YYYY-MM-DDTHH:mm:ss");
  }

  allFilteredList = [location, createdDate, lastOrderDate];

  // Remove the empty data.
  const filtered = allFilteredList.filter((data: any) => data !== "");
  const filterCount = filtered.length;
  // If the page is 'order', Items in the filtered array connect by using ';'.

  const filterQuery = filtered.join(";");
  // If the page is not 'order', Items in the filtered array connect by using '1'.
  return { filterCount, filterQuery };
};
