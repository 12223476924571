import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../pages/HomePage";

const TasksAppRoutes: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route path="/:locationId/inventory/order/:orderId" component={Home} exact />
    </Switch>
  );
};

export default TasksAppRoutes;
