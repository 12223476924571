import { jsPDF } from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { ExportPdfColor } from "../../../../../root/theme/exportColor";
import eatprestoLogo from "../../../../../assets/images/logos/logo.png";

/* Create a pdf file */
export const handleDownloadPdf = (
  scapeChange: string,
  columns: any,
  nodes: any,
  filterDetails: any,
) => {
  const doc: any = new jsPDF(scapeChange === "p" ? "p" : "l");
  const totalPagesExp = "{total_pages_count_string}";
  const locationFilter = filterDetails.split("*")[0];
  const pageWidth = doc.internal.pageSize.getWidth();
  const today = moment().format("MMM_Do_YY").toString();
  let locationLength = 0;

  if (!locationFilter.includes(",")) {
    locationLength = 10;
  }

  /* If there is a image, insert it into the img variable. */
  const img = new Image();
  img.src = eatprestoLogo;

  /* Create a pdf file. */
  doc.autoTable({
    columns: columns.map((col: any) => ({ ...col, dataKey: col.field })),
    body: nodes,
    startY: 58 + locationLength,
    styles: {
      align: "center",
      fontSize: 8,
      valign: "middle",
      halign: "center",
      borderRadius: "10px",
    },
    headStyles: {
      fillColor: [ExportPdfColor.HeaderRowColor],
      textColor: ["#ffffff"],
      fontSize: 7,
      valign: "middle",
      halign: "center",
    },
    columnStyles: {
      0: { halign: "left", cellWidth: 30 },
      10: { halign: "right" },
    },
    alternateRowStyles: {
      fillColor: [ExportPdfColor.OddRowColor],
      textColor: ["#ffffff"],
    },
    tableLineWidth: 0.1,

    // Add topic and sub topics.
    didDrawPage: function (data: any) {
      let pageNumber = "Page " + doc.internal.getNumberOfPages();
      // Change topic font size
      doc.setFontSize(12);
      doc.text("Presto Express Solutions", data.settings.margin.left, 12);
      // Modify the marginLeft according to the scape.
      doc.text("Customer List Reports", data.settings.margin.left + 227, 12);

      // Change main title font size
      doc.setFontSize(16);
      // Insert a topic for the first page only.
      if (doc.internal.getNumberOfPages() === 1) {
        /* Change the title according to the number of locations.
        If the location size is increased, show presto express. if the location size is the one, show that location. */
        doc.setTextColor("#32363E");
        if (locationFilter.includes(",")) {
        } else {
          doc.text(
            filterDetails
              .split("*")[0]
              .replace("Customer List summary report for location/s:", ""),
            pageWidth / 2,
            36,
            {
              align: "center",
            },
          );
        }

        doc.setTextColor(0, 0, 0);
        doc.text(
          "Customer Lists Report",
          pageWidth / 2,
          36 + locationLength,
          "center",
        );
        doc.setFontSize(10);

        /* Change the sub title according to the number of locations.
        If the location size is increased, show number of locations. if the location size is the one, show that location. */
        let filterLocationsDetails = "";
        if (locationFilter.split(",").length === 1) {
          filterLocationsDetails =
            "Customer List Summary Report for " + locationFilter.split(",")[0];
        } else {
          filterLocationsDetails =
            "Customer List Summary Report for " +
            locationFilter.split(",").length +
            " Locations";
        }

        doc.text(
          doc.splitTextToSize(filterLocationsDetails, 210 - 15 - 15),
          pageWidth / 2,
          44 + locationLength,
          {
            align: "center",
          },
        );

        /* Set the start date and end date */
        doc.text(
          doc.splitTextToSize(
            "Generated for the period of " + filterDetails.split("*")[1],
            210 - 15 - 15,
          ),
          pageWidth / 2,
          50 + locationLength,
          {
            align: "center",
          },
        );
      }

      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === "function") {
        pageNumber = pageNumber + " of " + totalPagesExp;
      }
      doc.setFontSize(10);
      /* Set page number */
      let pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      doc.text(pageNumber, data.settings.margin.left, pageHeight - 10);
      doc.addImage(
        img,
        "JPEG",
        data.settings.margin.left + 230,
        pageHeight - 24,
        40,
        16,
      );
    },
    /* Change the backgroundcolor in the row. */
    didParseCell: function (data: any, index: any) {
      const rows = data.table.body;

      if (data.section === "head") {
        if (data.column.dataKey === "Name") {
          data.cell.styles.halign = "left";
        }
      }

      if (data.column.index === 12) {
        data.cell.styles.halign = "right";
      }
      if (data.row.index !== 0) {
        if (data.row.index % 2 === 0) {
          data.cell.styles.fillColor = [ExportPdfColor.OddRowColor];
          data.cell.styles.textColor = ["#ffffff"];
        } else {
          data.cell.styles.fillColor = [ExportPdfColor.EvenRowColor];
          data.cell.styles.textColor = ["#ffffff"];
        }
      }
    },
    margin: {
      top: 30,
      bottom: 30,
    },
  });

  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }

  doc.save(
    `Customer_List_Report_${today}_${Math.floor(
      100000 + Math.random() * 900000,
    )}.pdf`,
  );
};
