import { axiosClient } from "../client";

const PUBLIC_SINGLE_STOCK_INFO_GET = (locationId, orderId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/public/location/${locationId}/stockOrder/id/${orderId}`;

const PUBLIC_STOCK_ORDER_STATUS_UPDATE = (locationId, orderId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/public/location/${locationId}/stockOrderStatus/id/${orderId}`;

const PUBLIC_STOCK_ORDER_ITEM_INFO_GET = (locationId, orderId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/public/location/${locationId}/stockOrderItem/stockOrder/${orderId}`;

export const getPublicSingleStockOrder = (locationId, orderId) =>
  axiosClient({
    url: PUBLIC_SINGLE_STOCK_INFO_GET(locationId, orderId),
    method: "GET",
  });

export const stockOrderStatusUpdate = (locationId, orderId) =>
  axiosClient({
    url: PUBLIC_STOCK_ORDER_STATUS_UPDATE(locationId, orderId),
    method: "POST",
  });

export const getPublicStockOrderItem = (locationId, orderId) =>
  axiosClient({
    url: PUBLIC_STOCK_ORDER_ITEM_INFO_GET(locationId, orderId),
    method: "GET",
  });
