import React, { useContext } from "react";
import TasksAppRoutes, {
} from "./routes/TasksAppRoutes";
import { ThemeProvider } from "@material-ui/core/styles";
import { initTheme } from "./theme/primary";
import PrimaryLayout from "../../layouts/PrimaryLayout";
import { GlobalContext } from "../../root/App";
import { CssBaseline } from "@material-ui/core";

export interface PrestoExpressCommonAppProps {}

const PrestoExpressCommonApp: React.FunctionComponent<PrestoExpressCommonAppProps> =
  () => {
    const { globalState } = useContext(GlobalContext);
    const theme = initTheme({ isDark: globalState.isDarkMode });
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <TasksAppRoutes />
      </ThemeProvider>
    );
  };

export default PrestoExpressCommonApp;
