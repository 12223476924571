import { Badge, IconButton, Typography, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import FilterCard from "./FilterCard/FilterCard";
import _ from "lodash";
import moment from "moment";
import TuneIcon from "@material-ui/icons/Tune";

import DefaultAlert from "../../alerts/DefaultAlert";
import { useRouteMatch, useLocation, useHistory } from "react-router";
import {
  getFilterListFromArrayObjectAndArray,
  getLabelListFromArrayObjectAndArray,
} from "../../../utils/commonArrayMap";
import { CustomTheme } from "../../../types/customTheme";
import { handleFilterDataCustomer } from "../../../utils/FilterDataCustomer";
import { handleFilterDataDetailsCustomer } from "../../../utils/FilterDetailsCustomer";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    iconButton: {
      "&:hover": {
        background: "none",
        backgroundColor: theme.palette.background.paper,
        border: `2px solid ${theme.palette.background.entity_border}`,
      },
      backgroundColor: theme.palette.background.entity_background,
      marginBottom: "8px",
      display: "flex",
      alignItems: "center",
      borderRadius: "10px",
      border: `2px solid ${theme.palette.background.entity_border}`,
      textAlign: "center",
      width: "120px",
      height: "44px",
    },
    badgeFontColor: {
      "& .MuiBadge-badge": {
        color: "white",
      },
    },
  }),
);

export interface Props {
  getFilterData: any;
  locationSelectorList: any;
  getLocationSelectorTypingList: any;
  isGetLocationInfo: any;
  setFilterDetails?: any;
  locationSelectedList: any;
  setLocationSelectedList: any;
  setOpenFilterCard: any;
  openFilterCard: any;
}

/**
 * Filter Component
 *
 * This React component is responsible for managing and applying filters to a dataset.
 * It provides a user-friendly interface for users to set filter criteria and adjust query parameters.
 * The component consists of a badge that displays the number of active filters and a filter card
 * that contains various filter controls such as text fields, dropdowns, date pickers, and switches.
 * Filters can be applied to refine the displayed data.
 *
 * Components and Features:
 * - Badge with the count of active filters.
 * - IconButton to toggle the filter card's visibility.
 * - FilterCard component that houses filter controls and options.
 * - Error alerts displayed when there are errors in filter settings.
 *
 * Props Passed to FilterCard Component:
 * - Various state variables and callback functions for managing filter settings and query parameters.
 *
 * Dependencies:
 * - Material-UI components for styling and user interface elements.
 *
 * Usage Example:
 * This component can be used in a web application to enhance the user's ability to filter and
 * narrow down a dataset based on specific criteria.
 */
const Filter: React.FunctionComponent<Props> = ({
  getFilterData,
  locationSelectorList,
  getLocationSelectorTypingList,
  isGetLocationInfo,
  setFilterDetails,
  locationSelectedList,
  setLocationSelectedList,
  setOpenFilterCard,
  openFilterCard,
}) => {
  const { search } = useLocation();
  const params: any = new URLSearchParams(search);
  const [error, setError] = useState("");
  const [locationFilterList, setLocationFilterList] = useState([]);
  const [searchLocationName, setSearchLocationName] = useState([]);
  const [createdStartDate, setCreatedStartDate] = useState<any>();
  const [createdEndDate, setCreatedEndDate] = useState<any>();
  const [createdSelectionRange, setCreatedSelectionRange] = useState<any>();
  const [lastOrderStartDate, setLastOrderStartDate] = useState<any>();
  const [lastOrderEndDate, setLastOrderEndDate] = useState<any>();
  const [lastOrderSelectionRange, setLastOrderSelectionRange] = useState<any>();

  const history = useHistory();
  const location = useLocation();
  const match: any = useRouteMatch();

  useEffect(() => {
    setCreatedSelectionRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setLastOrderSelectionRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  }, []);

  /**
   * Handles the selection of items for various filters and updates corresponding state variables.
   *
   * @param {string} selectorName - The name of the filter selector.
   * @param {Array} selectedList - The list of selected items for the filter.
   */
  const handleAllSelectedList = (selectorName: string, selectedList: any) => {
    // Create an array of selected item IDs.
    const selectedListIds = selectedList.map((item: any) => item.id);

    // Switch statement based on the dropdown selector name.
    switch (selectorName) {
      case "location":
        // Handle location filter.
        if (!_.isEmpty(selectedList)) {
          // Update location-related state variables.
          setLocationSelectedList(selectedList);
          setLocationFilterList(selectedListIds);
        } else if (_.isEmpty(selectedList)) {
          // No locations selected, get current location.
          const filteredCurrentLocation = locationSelectorList.filter(
            (itm: any) => itm.id === match.params.locationId,
          );

          // Get current location id.
          const currentLocationId = filteredCurrentLocation.map(
            (selectObject: any) => selectObject.id,
          );

          // Update location-related state variables with current location.
          setLocationSelectedList(filteredCurrentLocation);
          setLocationFilterList(currentLocationId);
        }
        break;

      default:
        break;
    }
  };

  const handleChangeCreatedDateRange = (range: any) => {
    setCreatedStartDate(
      moment(range.selection.startDate).format("YYYY-MM-DD"),
    );
    setCreatedEndDate(
      moment(range.selection.endDate).format("YYYY-MM-DD"),
    );
    setCreatedSelectionRange([range.selection]);

    const diff: number = moment(range.selection.endDate).diff(
      moment(range.selection.startDate),
      "days",
    );
  };

  const handleChangeLastOrderDateRange = (range: any) => {
    setLastOrderStartDate(
      moment(range.selection.startDate).format("YYYY-MM-DD"),
    );
    setLastOrderEndDate(
      moment(range.selection.endDate).format("YYYY-MM-DD"),
    );
    setLastOrderSelectionRange([range.selection]);

    const diff: number = moment(range.selection.endDate).diff(
      moment(range.selection.startDate),
      "days",
    );
  };

  /* On initial load, modify the data in the filter according to the query parameters. */
  useEffect(() => {
    const id: any = params.get("locationId");
    const createdStartDate: any = params.get("createdStartDate");
    const createdEndDate: any = params.get("createdEndDate");
    const lastOrderStartDate: any = params.get("lastOrderStartDate");
    const lastOrderEndDate: any = params.get("lastOrderEndDate");

    /* When there is no location id in the query parameters, 
    the corresponding location and this month are entered for the query parameters. */
    if (_.isEmpty(id) && isGetLocationInfo) {
      const filteredCurrentLocation = locationSelectorList.filter(
        (itm: any) => itm.id === match.params.locationId,
      );

      setLocationSelectedList(filteredCurrentLocation);
      handleAllSelectedList("location", filteredCurrentLocation);
    } else if (!_.isEmpty(params.toString()) && isGetLocationInfo) {
      if (id !== null) {
        const idList = [...id.split(",")];
        const filteredLocationArray = getFilterListFromArrayObjectAndArray(
          locationSelectorList,
          idList,
        );
        if (filteredLocationArray.length === 1) {
        }
        setLocationSelectedList(filteredLocationArray);
        handleAllSelectedList("location", filteredLocationArray);
      }

      if (createdStartDate !== null) {
        handleChangeCreatedDateRange({
          selection: {
            startDate: new Date(createdStartDate),
            key: "selection",
            endDate: new Date(createdEndDate),
          },
        });
      }
      if (lastOrderStartDate !== null) {
        handleChangeLastOrderDateRange({
          selection: {
            startDate: new Date(lastOrderStartDate),
            key: "selection",
            endDate: new Date(lastOrderEndDate),
          },
        });
      }
    }
  }, [isGetLocationInfo]);

  /* Remove empty query params */
  const handleRemoveEmptyQuery = (queryParam: any) => {
    const params = new URLSearchParams(queryParam);
    let keysForDel: any = [];
    params.forEach((value, key) => {
      if (value === "" || value === null) {
        keysForDel.push(key);
      }
    });
    keysForDel.forEach((key: any) => {
      params.delete(key);
    });
    return params;
  };

  /* Update query params when change the state */
  const handleUpdateQueryParams = () => {
    const queryParam = {
      locationId: locationFilterList.toString(),
      createdStartDate: createdStartDate?createdStartDate:"",
      createdEndDate: createdEndDate?createdEndDate:"",
      lastOrderStartDate: lastOrderStartDate?lastOrderStartDate:"",
      lastOrderEndDate: lastOrderEndDate?lastOrderEndDate:"",
    };
    const params = handleRemoveEmptyQuery(queryParam);
    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  /* Change the state after changing a query params. */
  const handleQueryParm = () => {
    const locationId: any = params.get("locationId");
    const createdStartDate: any = params.get("createdStartDate");
    const createdEndDate: any = params.get("createdEndDate");
    const lastOrderStartDate: any = params.get("lastOrderStartDate");
    const lastOrderEndDate: any = params.get("lastOrderEndDate");

    let arrLocation: any = [];
    if (!_.isEmpty(locationSelectorList) && !_.isEmpty(locationId)) {
      let idList = [...locationId.split(",")];
      arrLocation = getLabelListFromArrayObjectAndArray(
        locationSelectorList,
        idList,
      );
    }

    let uniqueLocation = [...new Set(arrLocation)];

    const filterDetails = handleFilterDataDetailsCustomer(
      uniqueLocation,
      createdStartDate,
      createdEndDate,
      lastOrderStartDate,
      lastOrderEndDate,
    );
    

    setFilterDetails(filterDetails);
  };

  useEffect(() => {
    if (params?.size === 0) {
      handleFilterRequest();
    }
    handleQueryParm();
  }, [params]);

  const handleFilterRequest = () => {
    // Check if location info needs to be fetched.
    if (isGetLocationInfo) {
      handleUpdateQueryParams();
    }

    // Perform filtering and obtain filter count and query.
    let { filterCount, filterQuery } = handleFilterDataCustomer(
      locationFilterList,
      createdStartDate,
      createdEndDate,
      lastOrderStartDate,
      lastOrderEndDate,
    );
    // Adjust filter count if certain conditions are met.
    if (isGetLocationInfo) {
      // Adjust filter count based on specific filters.

      // Fetch filter data based on the current filter query.
      getFilterData(filterQuery);
    }
  };

  /* The values ​​to be filtered are set as query parameters and provided that data to the API. */
  useEffect(() => {
    handleFilterRequest();
  }, [locationFilterList, createdStartDate, createdEndDate, lastOrderStartDate, lastOrderEndDate]);

  /* Open and close filter card */
  const handleChangeFilterCard = () => {
    setOpenFilterCard(!openFilterCard);
  };

  const handleClearLastOrderDateRange = () => {
    setCreatedSelectionRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setLastOrderSelectionRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setLastOrderStartDate("")
    setLastOrderEndDate("")
  }

  const handleClearFirstOrderDateRange = () => {
    setCreatedSelectionRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setCreatedStartDate("")
    setCreatedEndDate("")
  }

  const classes = useStyles();

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "start", marginTop: "16px" }}
      >
        <Badge
          badgeContent={3}
          color="secondary"
          className={classes.badgeFontColor}
        >
          <IconButton
            className={classes.iconButton}
            onClick={handleChangeFilterCard}
          >
            <Typography style={{ display: "flex", alignItems: "center" }}>
              <TuneIcon fontSize="small" />
            </Typography>
            <Typography style={{ marginLeft: "8px", letterSpacing: "1px" }}>
              Filters
            </Typography>
          </IconButton>
        </Badge>
      </div>
      {openFilterCard && (
        <FilterCard
          locationSelectorList={locationSelectorList}
          handleAllSelectedList={handleAllSelectedList}
          getLocationSelectorTypingList={getLocationSelectorTypingList}
          searchLocationName={searchLocationName}
          setSearchLocationName={(e: any) => setSearchLocationName(e)}
          locationSelectedList={locationSelectedList}
          handleChangeCreatedDateRange={handleChangeCreatedDateRange}
          createdSelectionRange={createdSelectionRange}
          createdStartDate={createdStartDate}
          createdEndDate={createdEndDate}
          handleChangeLastOrderDateRange={handleChangeLastOrderDateRange}
          lastOrderSelectionRange={lastOrderSelectionRange}
          lastOrderStartDate={lastOrderStartDate}
          lastOrderEndDate={lastOrderEndDate}
          handleClearLastOrderDateRange={handleClearLastOrderDateRange}
          handleClearFirstOrderDateRange={handleClearFirstOrderDateRange}
        />
      )}
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default Filter;
