import { Pagination } from "@material-ui/lab";
import React from "react";

export interface PaginationUsingPageNumberProps {
  handleChangePaginationUsingPageNumber: any;
  totalPages: any;
  currentPage: any;
}

/* Show the page number selection button. */
const PaginationUsingPageNumber: React.FunctionComponent<
  PaginationUsingPageNumberProps
> = ({ handleChangePaginationUsingPageNumber, totalPages, currentPage }) => {
  /* Given the selected page number. */
  const changeCurrentPage = (event: any, newPage: any) => {
    handleChangePaginationUsingPageNumber(newPage);
  };
  return (
    <>
      <div
        style={{
          marginTop: "10PX",
          justifyContent: "end",
          display: "flex",
        }}
      >
        <Pagination
          count={totalPages}
          page={currentPage}
          size={"small"}
          defaultPage={9}
          boundaryCount={3}
          onChange={changeCurrentPage}
        />
      </div>
    </>
  );
};

export default PaginationUsingPageNumber;
