import { Box, Button, Grid, Hidden, useMediaQuery } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";

import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import { Theme } from "../../../../types/customTheme";
import CardCommon from "../../../../components/card/CardCommon";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import { CapitalizeFirstLetter } from "../../../../utils/ReplaceIcon";
import { convertDateTimeFormatInventory } from "../../../../utils/ConvertDateTimeFormat";

interface styleProps {
  statusInitial: any;
}

const getColorByStatus = (statusInitial: any) => {
  switch (statusInitial.toLowerCase()) {
    case "ordered":
      return "#BA7134";
    case "delivered":
      return "#5BB995";
    case "draft":
      return "#FFB400";
    case "viewed":
      return "#39B3D1";
    default:
      return "#5BB995";
  }
};

const useStyles = makeStyles<any, styleProps>((theme: Theme) =>
  createStyles({
    gridField: {
      display: "flex",
      justifyContent: "center",
      placeItems: "center",
      fontSize: "13px",
      textTransform: "none",
      minHeight: "44px",
      color: theme.palette.custom.orange.contrastText,
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        minHeight: "0px",
        justifyContent: "center",
        marginTop: "6px",
      },
    },
    gridFieldLastColumn: {
      display: "flex",
      justifyContent: "flex-end",
      placeItems: "center",
      fontSize: "13px",
      textTransform: "none",
      minHeight: "44px",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "0px",
        minHeight: "0px",
        fontSize: "14px",
        justifyContent: "center",
      },
    },
    gridFieldFirstColumn: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      fontSize: "13px",
      paddingLeft: "16px",
      textTransform: "none",
      minHeight: "44px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
        fontSize: "14px",
        minHeight: "0px",
        marginTop: "12px",
        justifyContent: "center",
      },
    },
    status: {
      marginRight: "36px",
      [theme.breakpoints.down("sm")]: {
        marginRight: "0px",
      },
    },
    statusColor: {
      height: "100%",
      width: "24px",
      borderRadius: "10px",
      backgroundColor: ({ statusInitial }) => getColorByStatus(statusInitial),
      marginRight: "4px",
    },
  }),
);

export interface DiscountInfoNodeProps {
  nodeData: any;
  handleOpenAddStockOrderItemModal: any;
  supplierNode: any;
  handleOpenStockDeliveryItemModal: any;
  stockLevelNode: any;
  setSelectedSupplierName: any;
}

/*
  This React component, StockItemInfoNode, is responsible for rendering individual stock item information in a card format.
  It displays details such as supplier name, creation date, order date, delivery date, and status of the stock item.
  The component uses Material-UI for styling and responsiveness, and it adapts its layout based on the screen size.

  Key Features:
  - Displays stock item details in a card.
  - Handles different status styles and icons based on the stock item's status.
  - Allows user interaction to open modals for adding order items or viewing delivery details.
  - Utilizes responsive design for both small and medium/large screens.
*/
const StockItemInfoNode: React.FunctionComponent<DiscountInfoNodeProps> = ({
  nodeData,
  handleOpenAddStockOrderItemModal,
  supplierNode,
  handleOpenStockDeliveryItemModal,
  setSelectedSupplierName,
}) => {
  const [suppliedId, setSuppliedId] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [status, setStatus] = useState("");
  const [statusInitial, setStatusInitial] = useState("");
  const [createdTime, setCreatedTime] = useState("");
  const [deliveredTime, setDeliveredTime] = useState("-");
  const [orderedTime, setOrderedTime] = useState("-");
  const [orderId, setOrderId] = useState("");

  /**
   * This function is a callback used to update the component's state with information
   * from the provided `nodeValues`. It extracts relevant data from `nodeValues` and
   * sets it in the component's state variables.
   *
   * @param {object} nodeValues - An object containing stock item data.
   */
  const setNodeDataToState = useCallback((nodeValues) => {
    // Destructure relevant properties from the nodeValues object
    const { supplierId, created, id, deliveredOn, status, orderedOn } =
      nodeValues;

    // Set the order ID in the component's state
    setOrderId(id);

    // Set the supplied ID in the component's state
    setSuppliedId(supplierId.id);
    // Convert the status to lowercase and update it in the component's state
    // If the status is "draft," set it to "Pending"; otherwise, use the provided status
    const stat = status.toLowerCase() === "draft" ? "Pending" : status;
    setStatus(stat);

    // Set the initial status in the component's state
    setStatusInitial(status);

    // Convert the created date to the desired format and update it in the state
    setCreatedTime(convertDateTimeFormatInventory(created));

    // If the status is "delivered" and there's a deliveredOn date, set it in the state
    if (deliveredOn && status === "delivered") {
      setDeliveredTime(convertDateTimeFormatInventory(deliveredOn));
    }

    // If the status is "delivered" or "ordered" and there's an orderedOn date, set it in the state
    if (
      orderedOn &&
      (status === "delivered" || status === "ordered" || status === "viewed")
    ) {
      setOrderedTime(convertDateTimeFormatInventory(orderedOn));
    }

    // Get supplier details based on supplierId and set the supplier name in the state
    const supplierDetails = getFilterListFromArrayObject(
      supplierNode,
      supplierId.id,
    );

    // Check if supplierDetails is not empty; if so, set the supplier name from the details,
    // otherwise, set it to "Unknown supplier."
    if (!_.isEmpty(supplierDetails)) {
      setSupplierName(supplierDetails[0].name);
    } else {
      setSupplierName("Unknown supplier");
    }
  }, []);

  useEffect(() => {
    setNodeDataToState(nodeData);
  }, [nodeData, setNodeDataToState]);

  /**
   * This function renders the status of the stock item based on the given status value.
   * If the status is "draft", it renders the status in a plain format.
   * Otherwise, it renders the status using a class to apply custom styling.
   */
  const handleStatus = () => {
    if (
      status.toLowerCase() === "pending" ||
      status.toLowerCase() === "ordered" ||
      status.toLowerCase() === "delivered" ||
      status.toLowerCase() === "viewed"
    ) {
      if (status.toLowerCase() === "pending") {
        // If the status is "draft", render it in plain format
        return (
          <div className={classes.status}>{CapitalizeFirstLetter(status)}</div>
        );
      } else {
        // If the status is not "draft", apply custom styling using a class
        return (
          <div className={classes.status}>{CapitalizeFirstLetter(status)}</div>
        );
      }
    } else {
      return <div className={classes.status}>Delivered</div>;
    }
  };

  /**
   * This function is called when a button is clicked. It determines which action to perform
   * based on the initial status of the stock order.
   *
   * @param {ChangeEvent<HTMLInputElement>} event - The click event.
   */
  const handleClick = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedSupplierName(supplierName);
    // Check if the initial status of the stock order is "draft"
    if (statusInitial.toLowerCase() === "draft") {
      // If the status is "draft," open the "Add Stock Order Item" modal
      handleOpenAddStockOrderItemModal(orderId, suppliedId, nodeData, event);
    } else {
      // If the status is not "draft," open the "Stock Delivery Item" modal
      handleOpenStockDeliveryItemModal(orderId, suppliedId, nodeData, event);
    }
  };

  const maxWidth = useMediaQuery("(max-width: 960px)");
  const classes = useStyles({ statusInitial });
  return (
    <>
      <CardCommon backgroundColor={"entity_background"}>
        <Button
          style={{
            width: "100%",
            margin: "0px",
            padding: "0px",
            textTransform: "none",
          }}
          onClick={(event: any) => handleClick(event)}
        >
          <Grid container>
            <Hidden smDown>
              <Grid
                style={{ textAlign: "left" }}
                item
                xs={12}
                md={3}
                className={classes.gridFieldFirstColumn}
              >
                {CapitalizeFirstLetter(supplierName)}
              </Grid>
              <Grid
                style={{ textAlign: "left" }}
                item
                xs={12}
                md={2}
                className={classes.gridField}
              >
                {createdTime}
              </Grid>
              <Grid
                style={{ textAlign: "left" }}
                item
                xs={12}
                md={2}
                className={classes.gridField}
              >
                {orderedTime}
              </Grid>
              <Grid
                style={{ textAlign: "left" }}
                item
                xs={12}
                md={2}
                className={classes.gridField}
              >
                {deliveredTime}
              </Grid>
              <Grid
                item
                style={{ padding: "5px 2px" }}
                xs={12}
                md={3}
                className={classes.gridFieldLastColumn}
              >
                <Hidden smDown>
                  <div>{handleStatus()}</div>
                  <Box className={classes.statusColor}></Box>
                </Hidden>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid style={{ textAlign: "left", display: "flex" }} item xs={12}>
                <div style={{ minWidth: "calc(100vw - 78px)" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ marginTop: "8px" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {CapitalizeFirstLetter(supplierName)}{" "}
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {createdTime}
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {orderedTime}{" "}
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {deliveredTime}{" "}
                      </div>
                      <div
                        style={{
                          marginBottom: "8px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {handleStatus()}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    width: "40px",
                  }}
                >
                  <div style={{ marginTop: "8px", marginBottom: "8px" }}>
                    <Box className={classes.statusColor}></Box>
                  </div>
                </div>
              </Grid>
            </Hidden>
          </Grid>
        </Button>
      </CardCommon>
    </>
  );
};

export default withAuthority(StockItemInfoNode, Authorities.ORDERS_READ);
