import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useRouteMatch } from "react-router-dom";

import { getCookie } from "../../../../utils/cookies";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import CustomerHeader from "./CustomerNode";
import Pagination from "../../../../components/common/Pagination";
import { fetchAllCustomerInfo } from "../../../../services/customerApp/customerDetails";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import SaleReport from "../../../../components/common/SaleReport";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import { voidTransactionFilterObject } from "../../../../utils/consts/list";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";

export interface CustomerListPageProps {}

/**
 * CustomerListPage Component
 *
 * This component is responsible for rendering a paginated list of customer data.
 * It fetches customer information from an API and allows users to navigate through pages and sort the data.
 */

const CustomerListPage: React.FunctionComponent<CustomerListPageProps> = () => {
  const [customerNode, setCustomerNode] = useState<any>([]);
  const [pageSize, setPageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSort, setIsSort] = useState(false);
  const [headerName, setHeaderName] = useState("created");
  const [sortingMethod, setSortingMethod] = useState("ASC");
  const [disableButton, setDisableButton] = useState(false);
  const [filterDetails, setFilterDetails] = useState("");
  const [filterData, setFilterData] = useState("");
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [locationSelectedList, setLocationSelectedList] = useState([]);
  const [openFilterCard, setOpenFilterCard] = useState(false);

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");
  /**
   * handleGetCustomerDetails Function
   *
   * This function is responsible for fetching customer data from the API based on specified parameters.
   * It sets the page size, current page, and sorting method based on the input parameters.
   * It then makes an API call to fetch customer data and updates the state with the response.
   * If there is an error during the API call, it sets an error message in the state.
   * Finally, it sets the isLoading state to false to indicate that data fetching is complete.
   */
  const handleGetCustomerDetails = async (
    pageSize: any,
    currentPage: any,
    sortingMethod: any,
    headerName: any,
    filterData: any
  ) => {
    // Set the page size and current page in the state
    setPageSize(pageSize);
    setCurrentPage(currentPage);
    try {
      // Make an API call to fetch customer data
      const res = await fetchAllCustomerInfo(
        idToken,
        match.params.locationId,
        pageSize,
        currentPage,
        sortingMethod,
        headerName,
        filterData
      );

      // Update the state with the fetched customer data and total pages
      setCustomerNode(res.data.data);
      setTotalPages(res.data.totalPages);
    } catch (err) {
      // If there is an error during the API call, set an error message in the state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
    // Set isLoading state to false to indicate that data fetching is complete
    setIsLoading(false);
  };

  /* Get all location information using API call and response data set to locationSelectorList state. */
  const getAllFilterLocation = async () => {
    fetchAllFilterLocations("")
      .then((res) => {
        let locationList: any = [];
        /* Setting up the list of locations as needed to select locations. */
        if (!_.isEmpty(res.data.data)) {
          res.data.data.map((location: any) =>
            locationList.push({
              id: location.id,
              label: location.businessDisplayName,
            }),
          );
          const locationName = getFilterListFromArrayObject(
            locationList,
            match.params.locationId,
          );
        }

        setLocationSelectorList(locationList);
        setIsGetLocationInfo(true);
      })
      .catch(() => {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  /**
   * useEffect Hook
   *
   * This hook is used to perform side effects in the component.
   * In this case, it sets the document title, fetches customer data, and fetches location information.
   */
  useEffect(() => {
    // Set the document title
    document.title = "Customer - Customer List";

    // Fetch location information
    getAllFilterLocation();
  }, []);

  //Handle Customer information using selector
  const handleChangePaginationUsingSelector = (pageSize: any) => {
    setIsLoading(true);
    handleGetCustomerDetails(pageSize, 1, sortingMethod, headerName, filterData);
  };

  //Handle Customer information using page number
  const handleChangePaginationUsingPageNumber = (currentPage: any) => {
    setIsLoading(true);
    handleGetCustomerDetails(pageSize, currentPage, sortingMethod, headerName, filterData);
  };

  //sorting the Customer information using a header name
  const handleChangeOrderListSorting = (
    headerName: any,
    sortingMethod: any,
  ) => {
    setIsLoading(true);
    setIsSort(true);
    setDisableButton(true);
    setHeaderName(headerName);
    setSortingMethod(sortingMethod);
    handleGetCustomerDetails(pageSize, currentPage, sortingMethod, headerName, filterData);
  };

  //Remove sorting method
  const handleRemoveOrderListSorting = () => {
    setIsLoading(true);
    setIsSort(false);
    setDisableButton(false);
    setHeaderName("created");
    setSortingMethod("ASC");
    handleGetCustomerDetails(pageSize, currentPage, "", "", filterData);
  };

  /**
   * handleFilterData Function
   *
   * This function is responsible for handling the filtering of customer data.
   * It sets the isLoading state to true to indicate that data fetching is in progress.
   * It then sets the filterData state with the provided filter data.
   * Finally, it calls the handleGetCustomerDetails function with the current page size, current page, sorting method, and header name to fetch the filtered customer data.
   */
  const handleFilterData = (filterData: any) => {
    // Set isLoading state to true to indicate that data fetching is in progress
    setIsLoading(true);

    // Set the filterData state with the provided filter data
    setFilterData(filterData);

    // Fetch the filtered customer data using handleGetCustomerDetails function
    handleGetCustomerDetails(pageSize, currentPage, sortingMethod, headerName, filterData);
  };

  /**
   * handleOnClickText Function
   *
   * This function is responsible for handling the click event when a user clicks on a text.
   * It sets the openFilterCard state to true to indicate that the filter card is open.
   * It then scrolls the window to the top of the page with a smooth behavior.
   */
  const handleOnClickText = () => {
    // Set the openFilterCard state to true to indicate that the filter card is open
    setOpenFilterCard(true);

    // Scroll the window to the top of the page with a smooth behavior
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /*Get location list API call after typing. */
  const handleLocationSelectorTypingList = (searchName: any) => {};

  return (
    <>
      <SaleReport
        handleFilterData={handleFilterData}
        locationSelectorList={locationSelectorList}
        handleLocationSelectorTypingList={handleLocationSelectorTypingList}
        isGetLocationInfo={isGetLocationInfo}
        setFilterDetails={setFilterDetails}
        availableFilter={voidTransactionFilterObject}
        isOpenSkeletonLoading={isLoading}
        nodeList={customerNode}
        filterDetails={filterDetails}
        title={""}
        isChart={false}
        isUserGet={false}
        isSale={false}
        topic="Customer List"
        locationSelectedList={locationSelectedList}
        setLocationSelectedList={setLocationSelectedList}
        openFilterCard={openFilterCard}
        setOpenFilterCard={setOpenFilterCard}
        handleOnClickText={handleOnClickText}
        isReport={false}
        errorTitle={"Customer"}
        isCustomer
      >
        <CustomerHeader
          customerNode={customerNode}
          isLoading={isLoading}
          isSort={isSort}
          sortingMethod={sortingMethod}
          handleChangeOrderListSorting={handleChangeOrderListSorting}
          handleRemoveOrderListSorting={handleRemoveOrderListSorting}
          disableButton={disableButton}
          headerName={headerName}
          locationSelectedList={locationSelectedList}
          handleOnClickText={handleOnClickText}
          filterDetails={filterDetails}
        />
        {!_.isEmpty(customerNode) && (
          <Pagination
            handleChangePaginationUsingSelector={
              handleChangePaginationUsingSelector
            }
            handleChangePaginationUsingPageNumber={
              handleChangePaginationUsingPageNumber
            }
            totalPages={totalPages}
            currentPage={currentPage}
            pageSize={pageSize}
            isCustomer={true}
          />
        )}
      </SaleReport>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(CustomerListPage, Authorities.CUSTOMER_LIST_READ);
